/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import get from 'lodash/get';
import { getValidationDatabyType } from '../../DynamicForm/helper/filter';
import { checkValueTypeAndGetTheCount } from '../../../../business/Utils/helper';
import { isNullorEmpty, stringToBoolean } from '../../../../../utils/helperUtils';

/**
 *
 * @param {*} props
 */
const CheckboxGroup = (props) => {
  const {
    name,
    control,
    requiredValidation,
    dataValidations,
    mandatory,
    t,
    customError,
    label,
    setValue,
    defaultOptions,
    options,
    isPicker,
    updateDefaultOptions,
    id,
    showBasedOnFieldName,
    hideBasedOnFieldName,
    formName,
    watch,
    value,
    isHideField,
    checkedValues,
    setCheckedValues,
  } = props;
  const fieldError = get(customError, name);
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(isHideField);
  const showFieldName =
    formName && showBasedOnFieldName
      ? `${formName}[${showBasedOnFieldName}]`
      : showBasedOnFieldName;
  const hideFieldName =
    formName && hideBasedOnFieldName
      ? `${formName}[${hideBasedOnFieldName}]`
      : hideBasedOnFieldName;

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;
  const mandatoryFlag = !isNullorEmpty(mandatory)
    ? stringToBoolean(mandatory)
    : true;

  useEffect(() => {
    if (showFieldValue !== null && typeof showFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue !== null && typeof hideFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  const handleSelect = (checkedName) => {
    const newNames = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newNames);
    updateDefaultOptions(newNames);
    return newNames;
  };

  useEffect(() => {
    if (defaultOptions.length > 0) {
      let newArrSet = [...defaultOptions, ...checkedValues];
      let result = [...new Set(newArrSet)];
      setValue(name, result, { shouldValidate: true, shouldDirty: true });
      setCheckedValues(result);
    }
  }, [value]);

  return (
    <fieldset>
      <ul
        className={`form-checkbox-group checklist ${
          fieldError ? 'error withoutBg' : ''
        } ${!showField || hideField ? 'hide' : ''}`}
        role="group"
        style={props?.toggleStyle}
      >
        {options.map((item, index) => (
          <li
            className="checklist__item"
            key={index}
            className={isPicker && !checkedValues?.includes(item) ? 'hide' : ''}
          >
            <div
              className={`${
                !isPicker ? 'form-block checkbox' : ''
              } checkbox--toggle`}
            >
              <Controller
                name={name}
                render={({ onChange: onCheckChange, onBlur }) => {
                  return (
                    <input
                      name={name}
                      type="checkbox"
                      checked={checkedValues?.includes(item)}
                      onChange={() => onCheckChange(handleSelect(item))}
                      onBlur={onBlur}
                      id={`${id}${index}`}
                    />
                  );
                }}
                rules={{
                  required:
                    showField && requiredData?.fields?.value?.value && mandatoryFlag
                      ? !hideField
                      : false,
                }}
                control={control}
              />
              <label htmlFor={`${id}${index}`}>{item}</label>
            </div>
          </li>
        ))}
      </ul>
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
    </fieldset>
  );
};

CheckboxGroup.defaultProps = {
  options: [],
  checkedValues: [],
  defaultOptions: [],
  toggleStyle: {},
  selectHandler: () => {},
  t: () => {},
  isPicker: false,
  updateDefaultOptions: () => {},
};

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  toggleStyle: PropTypes.shape({}),
  checkedValues: PropTypes.arrayOf(PropTypes.string),
  defaultOptions: PropTypes.arrayOf(PropTypes.string),
  selectHandler: PropTypes.func,
  t: PropTypes.func,
  isPicker: PropTypes.bool,
  updateDefaultOptions: PropTypes.func,
};

export default CheckboxGroup;
