/* eslint-disable max-lines-per-function */
/* eslint-disable react/display-name */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';

import Table from '../../../../core/Table';
import { useFetchData, usePostData } from '../../../../../hooks/http-client';
import { ACCOUNT_SETTINGS, API_STATUS_CODES } from '../../../../../constants';
import Button from '../../../../core/globals/buttons/Button';
import AlertPopUp from '../../../../core/ErrorBoundary/AlertPopUp';
import AlertInline from '../../../../core/ErrorBoundary/AlertInline';
import { connect } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
/**
 * @description - to render the dropdown
 * @param {Object} frequency - frequency items list
 * @param {func} handleAlertUpdate - update selected item in the list
 * @param {Object} row - selected row
 * @returns {HTML} - dropdown
 */
const PreferenceDropDown = ({ frequency, handleAlertUpdate, row }) => {
  const [dropdown, setDropDown] = useState(false);

  useEffect(() => {
    /**
     * @description - to hide dropdown on tab focus out
     * @param {*} event - event
     * @returns {Object} - event listener
     */
    const listener = (event) => {
      if (
        event &&
        event.keyCode === 9 &&
        !event.target?.className.includes('dropdown-item')
      ) {
        setDropDown(false);
      }
    };
    document.addEventListener('keyup', listener);
    return () => {
      document.removeEventListener('keyup', listener);
    };
  }, []);

  return (
    <Dropdown show={dropdown}>
      <Dropdown.Toggle
        className="btn-dp-down"
        id="dropdown-basic"
        onClick={() => setDropDown(!dropdown)}
      >
        {frequency?.map((item) => {
          if (item?.fields?.key?.value === row.view) {
            return item?.fields?.label?.value?.toUpperCase();
          }
        })}
        <div className="drop-icon" />
      </Dropdown.Toggle>
      <Dropdown.Menu role="menu" onMouseLeave={() => setDropDown(false)}>
        {frequency?.map((item, index) => {
          return (
            <Dropdown.Item
              key={index}
              onClick={() => {
                handleAlertUpdate(item?.fields?.key?.value, row.id);
              }}
              role="menuitem"
            >
              {item?.fields?.label?.value?.toUpperCase()}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

PreferenceDropDown.defaultProps = {
  frequency: {},
  handleAlertUpdate: () => {},
  row: {},
};

PreferenceDropDown.propTypes = {
  frequency: PropTypes.shape({}),
  handleAlertUpdate: PropTypes.func,
  row: PropTypes.shape({}),
};

/**
 * @description - Communication Preference component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const CommunicationPreference = (props) => {
  const { t, icon, fields, clearAlerts, sitecoreContext } = props;
  const { frequency } = fields;
  const { load, loading } = useFetchData();
  const { write, error } = usePostData();
  const [alertList, setAlertList] = useState([]);
  const [response, setResponse] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [delFunc, setDelFunc] = useState(null);
  const [message, setMessage] = useState({});
  const [updateProgress, setUpdateProgress] = useState(false);

  useEffect(() => {
    if (error) {
      setMessage({
        ...message,
        ...{ type: 'error', message: t('http-error') },
      });
    }
  }, [error]);

  useEffect(() => {
    if (clearAlerts === true) {
      setAlertList([]);
    }
  }, [clearAlerts]);

  useEffect(() => {
    if (message) {
      setTimeout(() => setMessage(null), 5000);
    }
  }, [message]);

  /**
   * @description to handle alert pop up
   * @returns {undefind} no return
   */
  const handleModal = () => {
    setShowModal(!showModal);
  };

  /**
   * @description to update alert frquency
   * @param {string} value - new frequency
   * @param {string} id - job alert id
   * @returns {undefined} - no return
   */
  const handleAlertUpdate = (value, id) => {
    response.map((alertItem) => {
      if (alertItem.alertID === id) {
        alertItem.frequencyType = value;
      }
      return alertItem;
    });
    prepareTableRows(response);
  };

  /**
   * @description to delete job alert
   * @param {number} id - job alert id
   * @returns {undefined} no returns
   */
  const handleDelete = (id) => {
    setMessage(null);
    write(
      `${ACCOUNT_SETTINGS.COMMUNICATION_PREFERENCES.DELETE_ALERT_API}?alertID=${id}`,
      null,
      handleDeleteResponse
    );
  };

  /**
   *
   * @param {object} response - api response
   * @returns {undefined} - no returns
   */
  const handleDeleteResponse = (response) => {
    if (response.data.status === API_STATUS_CODES.SUCCESS) {
      getAlerts();
    }
  };

  const columns = useMemo(
    () => [
      {
        selector: 'title',
        grow: 2,
        cell: (row) => {
          return <div className="alert-title">{row.title}</div>;
        },
      },
      {
        selector: 'view',
        right: true,
        cell: (row) => {
          return (
            <div className="view-or-remove pref-button">
              <PreferenceDropDown
                frequency={frequency}
                handleAlertUpdate={handleAlertUpdate}
                row={row}
              />
              <div className="vertical-seperator" />
              <button
                // eslint-disable-next-line jsx-a11y/aria-props
                aria-description="Pop-up box opens"
                className="remove-cell"
                aria-label={`${t('remove')} pop-up box opens`}
                onClick={() => {
                  setDelFunc(row.id);
                  handleModal();
                }}
              >
                {t('remove')}
              </button>
            </div>
          );
        },
      },
    ],
    [alertList]
  );

  /**
   * @description - callback for successful api reponse
   * @param {object} response - api response object
   * @returns {undefined} - no returns
   */
  const setData = (response) => {
    if (sitecoreContext?.Country?.name?.toLowerCase() === 'us') {
      const reversedData = response?.data?.jobAlerts?.reverse();
      setResponse(reversedData);
      prepareTableRows(reversedData);
    } else {
      setResponse(response.data.jobAlerts);
      prepareTableRows(response.data.jobAlerts);
    }
  };

  /**
   *
   * @param {array} alertList - list of alert items
   * @returns {undefined} - no return
   */
  const prepareTableRows = (alertList = []) => {
    const data = [];
    alertList.forEach((alerts, index) => {
      data[index] = {
        title: alerts.jobAlertName,
        view: alerts.frequencyType,
        id: alerts.alertID,
      };
    });
    setAlertList(data);
  };

  /**
   * @description get alert list
   * @returns {undefined} nothing
   */
  const getAlerts = () => {
    load(ACCOUNT_SETTINGS.COMMUNICATION_PREFERENCES.GET_ALERT_API, null, setData);
  };

  /**
   * @description to save alert changes
   * @returns {undefined} - nothing
   */
  const handleSave = () => {
    setMessage(null);
    setUpdateProgress(true);
    write(
      ACCOUNT_SETTINGS.COMMUNICATION_PREFERENCES.UPDATE_ALERT_API,
      {
        jobAlerts: response,
      },
      handleSaveResponse
    );
  };

  /**
   * to handle save response
   * @param {object} apiresponse - alert update api response
   * @returns {undefined} - no returns
   */
  const handleSaveResponse = (apiresponse) => {
    setUpdateProgress(false);
    setMessage(null);
    if (apiresponse.data.status === API_STATUS_CODES.SUCCESS) {
      setMessage({
        ...message,
        ...{ type: 'success', message: t('job-alert-updated') },
      });
    } else {
      setMessage({
        ...message,
        ...{ type: 'error', message: t('job-alert-update-failed') },
      });
    }
  };

  useEffect(() => {
    getAlerts();
  }, []);

  return (
    <>
      <div className="dashboard-agent dashboard-block communication-preferences">
        {(sitecoreContext?.Country?.name?.toLowerCase() === 'us' && alertList?.length > 0) && <p className="description">{fields?.description?.value}</p>}
        <br aria-hidden={true} />
        <Table
          noTableHead={true}
          columns={columns}
          icon={icon}
          row={alertList}
          showHeader={false}
          progressPending={loading}
        />
        <br aria-hidden={true} />
        <div className="btn-container">
          {alertList.length > 0 && (
            <Button
              text={t('save-changes')}
              handleButtonClick={handleSave}
              isLoading={updateProgress}
              cssClass="orange-bg"
            />
          )}
          {message?.type && (
            <AlertInline
              message={message?.message}
              type={message?.type}
              key={new Date()}
            ></AlertInline>
          )}
        </div>
      </div>
      <AlertPopUp
        message={t('delete-confirmation-jobalert')}
        btnOkText={t('yes')}
        btnCancelText={t('no')}
        visibility={showModal}
        handleModal={handleModal}
        callback={() => handleDelete(delFunc)}
      ></AlertPopUp>
    </>
  );
};
CommunicationPreference.defaultProps = {
  title: 'Saved Jobs',
  t: () => {},
};

CommunicationPreference.propTypes = {
  title: PropTypes.string.isRequired,
  t: PropTypes.func,
};

const mapStateToProps = (state) => ({
  clearAlerts: state.consentReducer.clearAlerts,
});

export default withSitecoreContext()(
  withTranslation()(connect(mapStateToProps)(CommunicationPreference))
);
