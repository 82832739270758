import React, { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import set from 'lodash/set';
import './jobpriorities.scss';

const JobPriorities = (props) => {
  const { formProps, form, options, register } = props;
  const { value } = props;
  const { setValue, name, validateOnLoad, formState } = formProps;
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    if (value) {
      // Add this section after the api is working
    } else {
      const modifiedData = options.map((item, index) => ({
        ...item,
        order: String(index + 1),
        id: String(index + 1),
      }));
      setItems(modifiedData);
    }
  }, [value]);

  const convertToApiFormat = (data) => {
    const result = {};
    data.forEach((item) => {
      result[item.name] = item.order;
    });
    return JSON.stringify(result);
  };
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const updatedItems = [...items];
    const movedItem = updatedItems.find((item) => item.id === result.draggableId);

    // Remove the moved item from the list
    updatedItems.splice(result.source.index, 1);
    // Insert the moved item at its new position
    updatedItems.splice(result.destination.index, 0, movedItem);
    // Reassign order values to all items based on their new positions
    updatedItems.forEach((item, index) => {
      item.order = index + 1;
    });
    setItems(updatedItems);
    const param = validateOnLoad ? { shouldValidate: true } : {};
    setValue(name, convertToApiFormat(updatedItems), param);
    set(formState.touched, name, true);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      className="jobprioritiesList"
                    >
                      <div className="jobpriorities">
                        {item.order} || {item.label}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <input
        type="hidden"
        name={`${form?.fields?.name?.value}`}
        ref={register ? register : () => {}}
      />
    </>
  );
};

export default JobPriorities;
